/* eslint-disable */
// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});



// Load Events
jQuery(document).ready(() => routes.loadEvents());


/*
* Top Menu
*/
$('.menu-item-has-children').attr('aria-haspopup', 'true'); // IE 10 braucht das

var span = '<span class="new" title="öffnet Submenu"></span>';
$( '.menu-item-has-children').append(span);
$('.menu-item-has-children').on('click','span.new', function() {

	$(this).toggleClass('subshow'); // Wird verwendet um das Minus und Plus Zeichen zu wechseln
	//detect IE 10 win 8 
	if(!navigator.msMaxTouchPoints){
		$(this).siblings('.sub-menu').slideToggle('fast');
	}
	
});


/*
* Slider
*/
var itemsMainDiv = ('.MultiCarousel');
var itemsDiv = ('.MultiCarousel-inner');
var itemWidth = "";
var clicksDone = 0;
var sichtbar = 0;
const leftSlideBTN = document.getElementById('sliderLeft');
const rightSlideBTN = document.getElementById('sliderRight');

$('.leftLst, .rightLst').on("click", function() {
    var condition = $(this).hasClass("leftLst");
    
    if (condition) {
        clicksDone--;
        click(0, this);
    }            
    else {
        clicksDone++;
        click(1, this);
    }
    var itemNumbers = $('.item').length;
    // console.log(itemNumbers + ' Anzahl sichtbar = ' + sichtbar);
    // console.log('clickDone = ' + clicksDone);
    if (clicksDone == itemNumbers) {
        // $('.rightLst').hide();
        rightSlideBTN.style.display = 'none';
    } else {
        rightSlideBTN.style.display = 'flex';
    }

    if (clicksDone == sichtbar) {
        leftSlideBTN.style.display = 'none';
    } else {
        leftSlideBTN.style.display = 'flex';
    }
});

ResCarouselSize();

// console.log('clickDone = ' + clicksDone);
if(leftSlideBTN) leftSlideBTN.style.display = 'none';

$(window).on("resize", function() {
    ResCarouselSize();
});

//this function define the size of the items
function ResCarouselSize() {
    var incno = 0;
    var dataItems = ("data-items");
    var itemClass = ('.item');
    var id = 0;
    var btnParentSb = '';
    var itemsSplit = '';
    var sampwidth = $(itemsMainDiv).width();
    var bodyWidth = $('#img-slider').width();        
    $(itemsDiv).each(function () {
        id = id + 1;
        var itemNumbers = $(this).find(itemClass).length;
        btnParentSb = $(this).parent().attr(dataItems);
        itemsSplit = btnParentSb.split(',');
        $(this).parent().attr("id", "MultiCarousel" + id);
        
        if (bodyWidth >= 640) {
            itemWidth = Math.round(bodyWidth / 3);
            sichtbar = 3;
        }  
        else if (bodyWidth >= 540) {
            itemWidth = Math.round(bodyWidth / 2);
            sichtbar = 2;
        }
        else {
            itemWidth = Math.round(bodyWidth);
            sichtbar = 1;
        }
        clicksDone += sichtbar;

        
        // else if (bodyWidth >= 700) {
                
        //         // incno = itemsSplit[1];
        //         // itemWidth = sampwidth / incno;
        //         itemWidth = Math.round(bodyWidth/3);
        //         // itemWidth = 230;
        // }
        // else if (bodyWidth >= 992) {
        //     incno = itemsSplit[2];
        //     itemWidth = sampwidth / incno;
        // }
        // else if (bodyWidth >= 768) {
        //     incno = itemsSplit[1];
        //     itemWidth = sampwidth / incno;
        // }
        // else {
        //     incno = itemsSplit[0];
        //     itemWidth = sampwidth / incno;
        // }
        $(this).css({ 'transform': 'translateX(0px)', 'width': itemWidth * itemNumbers });
        $(this).find(itemClass).each(function () {
            $(this).outerWidth(itemWidth);
        });

        $(".leftLst").addClass("over");
        $(".rightLst").removeClass("over");

    });
}


//this function used to move the items
function ResCarousel(e, el, s) {
    var leftBtn = ('.leftLst');
    var rightBtn = ('.rightLst');
    var translateXval = '';
    var divStyle = $(el + ' ' + itemsDiv).css('transform');
    var values = divStyle.match(/-?[\d\.]+/g);
    var xds = Math.abs(values[4]);
    if (e == 0) {
        translateXval = parseInt(xds) - parseInt(itemWidth * s);
        $(el + ' ' + rightBtn).removeClass("over");

        if (translateXval <= itemWidth / 2) {
            translateXval = 0;
            $(el + ' ' + leftBtn).addClass("over");
        }
    }
    else if (e == 1) {
        var itemsCondition = $(el).find(itemsDiv).width() - $(el).width();
        translateXval = parseInt(xds) + parseInt(itemWidth * s);
        $(el + ' ' + leftBtn).removeClass("over");

        if (translateXval >= itemsCondition - itemWidth / 2) {
            translateXval = itemsCondition;
            $(el + ' ' + rightBtn).addClass("over");
        }
    }
    $(el + ' ' + itemsDiv).css('transform', 'translateX(' + -translateXval + 'px)');

}

//It is used to get some elements from btn
function click(ell, ee) {
    var Parent = "#" + $(ee).parent().attr("id");
    var slide = $(Parent).attr("data-slide");
    ResCarousel(ell, Parent, slide);        
}


/*
* Ajax Load More Rezepte
*/
const loadMoreRezepteBTN = document.querySelector(".load-more-rezepte");
const noMore = document.getElementById('no-more-content');
const target = document.getElementById('rezepte-content');
const spinner = document.querySelector('.at-spinner');

let pagenumber = 1;

const numberOffNewPost = 3;

if (noMore) {
    noMore.style.display = 'none';
}

function getMore(pagenumber,offset) {

    var newOffsetValue = offset + numberOffNewPost;

    fetch(frontend_ajax_object.ajaxurl,
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: "action=load_more_posts&pagenumber="+ pagenumber +"&offset=" + offset ,
    }
    )
    .then(function (response) { return response.text(); })
    .then(function (txt) {
        
        if (txt != 0) {
            target.innerHTML += txt;

            var anzahl = (txt.match(new RegExp("<h4", "g")) || []).length; //logs 4
        
            loadMoreRezepteBTN.setAttribute('data-offset', newOffsetValue);
            spinner.style.display = 'none';

            if (anzahl == numberOffNewPost) {
                loadMoreRezepteBTN.style.display = "block";
            } else {
                noMore.style.display = 'block';
            }
            
        } else {
            loadMoreRezepteBTN.style.display = 'none';
            noMore.style.display = 'block';
            spinner.style.display = 'none';
        }

    })

}

if (loadMoreRezepteBTN) {

    loadMoreRezepteBTN.addEventListener("click", function () {

        spinner.style.display = 'block';
        this.style.display = "none";

        pagenumber++;
        
        var offset = parseInt(this.getAttribute('data-offset'));

        getMore(pagenumber,offset);

  });
}














const doc = document.querySelector.bind(document)
if (doc("#sheet")) {
    const sheet = doc("#sheet")
    const sheetContents = sheet.querySelector(".contents")
    const draggableArea = sheet.querySelector(".draggable-area")
    const openSheetButton = doc("#open-sheet")
    let sheetHeight // in vh
    const setSheetHeight = (value) => {
        sheetHeight = Math.max(0, Math.min(100, value))
        sheetContents.style.height = `doc{sheetHeight}vh`
        if (sheetHeight === 100) {
            sheetContents.classList.add("fullscreen")
        } else {
            sheetContents.classList.remove("fullscreen")
        }
    }
    const setIsSheetShown = (value) => {
        sheet.setAttribute("aria-hidden", String(!value))
    }
    // Open the sheet when clicking the 'open sheet' button
    openSheetButton.addEventListener("click", () => {
        setSheetHeight(Math.min(50, 720 / window.innerHeight * 100))
        setIsSheetShown(true)
        openSheetButton.style.display = "none";
        document.body.classList.add('no-scroll')
    });
    // Hide the sheet when clicking the 'close' button
    sheet.querySelector(".close-sheet").addEventListener("click", () => {
        setIsSheetShown(false)
        openSheetButton.style.display = "block";
        document.body.classList.remove('no-scroll')
    })
    // Hide the sheet when clicking the background
    sheet.querySelector(".overlay").addEventListener("click", () => {
        setIsSheetShown(false)
    })
    const touchPosition = (event) =>
        event.touches ? event.touches[0] : event
    let dragPosition
    const onDragStart = (event) => {
        dragPosition = touchPosition(event).pageY
        sheetContents.classList.add("not-selectable")
        draggableArea.style.cursor = document.body.style.cursor = "grabbing"
    }
    const onDragMove = (event) => {
        if (dragPosition === undefined) return
        const y = touchPosition(event).pageY
        const deltaY = dragPosition - y
        const deltaHeight = deltaY / window.innerHeight * 100
        setSheetHeight(sheetHeight + deltaHeight)
        dragPosition = y
    }
    const onDragEnd = () => {
        dragPosition = undefined
        sheetContents.classList.remove("not-selectable")
        draggableArea.style.cursor = document.body.style.cursor = ""
        if (sheetHeight < 25) {
            setIsSheetShown(false)
        } else if (sheetHeight > 75) {
            setSheetHeight(100)
        } else {
            setSheetHeight(50)
        }
    }
    draggableArea.addEventListener("mousedown", onDragStart)
    draggableArea.addEventListener("touchstart", onDragStart)
    window.addEventListener("mousemove", onDragMove)
    window.addEventListener("touchmove", onDragMove)
    window.addEventListener("mouseup", onDragEnd)
    window.addEventListener("touchend", onDragEnd)
}